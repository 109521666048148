<template xmlns:v-bind="http://www.w3.org/1999/xhtml">
    <header class="mobag-header">
      <div class="dealer-login" style="height: 5px;">
<!--        <a v-show="$i18n.locale != 'en'" @click="$i18n.locale='en'; $router.push('/')"><v-icon class="icon" name="globe"></v-icon>English</a>-->
<!--        <a v-show="$i18n.locale != 'cn'" @click="$i18n.locale='cn'; $router.push('/')"><v-icon class="icon" name="globe"></v-icon>中文</a>-->
<!--        <a v-show="$i18n.locale == 'en'" class="loginEntry forWeb" @click="linkToReg" ><v-icon class="icon" name="store"></v-icon>Become a Dealer</a>-->
<!--        <a v-show="$i18n.locale == 'cn'" class="loginEntry forWeb" @click="linkToReg" ><v-icon class="icon" name="store"></v-icon>成为经销商</a>-->
<!--        <a v-show="$i18n.locale == 'en'" class="loginEntry forWeb" @click="linkToDealer" id="dealerLogin"><v-icon class="icon" name="pen-alt"></v-icon>Dealer Login</a>-->
<!--        <a v-show="$i18n.locale == 'cn'" class="loginEntry forWeb" @click="linkToDealer" id="dealerLogin"><v-icon class="icon" name="pen-alt"></v-icon>经销商登录</a>-->
<!--        <a v-show='!isUserLogin' class="loginEntry forWeb" @click="userLogin()"><v-icon class="icon" name="user-circle"></v-icon>用户登录</a>-->

        <!--手机登录入口-->
<!--        <a v-show='!isUserLogin' class="loginEntry forMobile" @click="linkToDealer" >-->
<!--          <v-icon class="icon" name="pen-alt"></v-icon>{{ $t("header.dealerLogin") }}-->
<!--        </a>-->
<!--        <a v-show='!isUserLogin' class="loginEntry forMobile" @click="tangleLoginModal()" >   -->
<!--          <v-icon class="icon" name="user-circle"></v-icon>用户登录-->
<!--        </a>-->
        <!-- end -->

<!--        <a v-show="isUserLogin" class="loginEntry" @click="userLogout()">-->
<!--          <v-icon class="icon" name="user-circle"></v-icon>注销登录 ({{nickName || phone}})-->
<!--        </a>-->
      </div>
      <div class="mobag-header-container">
        <div class="mobag-menu-icon" @click="tangleMenuModal()"><v-icon name="bars"/></div>
        <div class="mobag-logo">
          <a href="">
            <img src="https://cdn.mobag.cn/BestValueLogo.png">
          </a>
        </div>
        <nav class="mobag-nav">
          <a href="#" class="nav_a" @click="(e) => {reloadPage('/home', e)}">{{ $t("header.homepage") }}</a>
          <a href="#" class="nav_a" @click="(e) => {reloadPage('/home/product/list/tag/0', e)}">{{ $t("header.parts") }}</a>
          <a href="#" class="nav_a" @click="(e) => {reloadPage('/home/product/list/tag/4', e)}">{{ $t("header.allGears") }}</a>
          <!-- <a href="#" class="nav_a" @click="(e) => {reloadPage('/home/product/quotaion/list/page', e)}">{{ $t("header.quotationPage") }}</a> -->
          <a target="_blank" href="https://dealer.bestvkitchen.com/">{{ $t("header.dealerLogin") }}</a>
<!--          <router-link :to="{name: 'productListByTag', params: {tagType: 1}}">{{ $t("header.helmets") }}</router-link>-->
<!--          <router-link :to="{name: 'productListByTag', params: {tagType: 2}}">{{ $t("header.ridingGear") }}</router-link>-->
<!--          <router-link :to="{name: 'productListByTag', params: {tagType: 3}}">{{ $t("header.accessories") }}</router-link>-->
        </nav>
        <div class="mobag-search">
          <input type="text" name="search_input" v-model="searchKeyword" @keydown.enter="search()" :placeholder="$t('header.searchPlaceHolder')" />
          <a @click="search()"><v-icon class="icon-search" name="search"/></a>
        </div>
        <div class="mobag-search-mobile">
          <a @click="tangleSearchModal()" class="mobag-search-mobile">
            <v-icon class="icon-search" name="search"/>
          </a>
        </div>
        <mobile-search @modalSearch="getSearchParam" ref="mobileSearch" @tangle="tangleSearchModal()"></mobile-search>
        <mobile-menu ref="mobileMenu" @tangle="tangleMenuModal()" />
<!--        <mobile-login ref="mobileLogin" @tangle="tangleLoginModal()" @loginCallBack="loginCallBack" />-->
<!--        <login :modalName="userloginModal" @loginCallBack="loginCallBack"></login>-->
      </div>
    </header>
</template>

<script>
  import MobileSearch from '../components/mobile/Search.vue'
  import MobileMenu from '../components/mobile/Menu.vue'
  import LoginMenu from '../components/mobile/LoginByAccount.vue'
  import { baseUrl } from '../util.js'
  import UserLoginDialogue from '../components/web/UserLoginDialogue.vue'

  export default {
    components: {
      'mobile-search': MobileSearch,
      'mobile-menu': MobileMenu,
      'login': UserLoginDialogue,
      'mobile-login': LoginMenu,
    },
    data: function() {
      return {
        isMenuShow: false,
        menuDisplay: 'none',
        menuLeftValue: '-100%',
        searchKeyword: '',
        brands: '',
        isSearchOpen: false,
        isMenuOpen: false,
        isLoginOpen: false,
        userloginModal: 'userLoginModal',
        isUserLogin:false,
        nickName: undefined,
        phone: undefined,
      }
    },
    // beforeMount() {
    //   this.checkLoginStatus();
    // },
    methods: {
      // logoutCallback() {
      //   this.nickName = '';
      //   this.phone = '';
      //   this.isUserLogin = false;
      //   this.storeUserInfo(this.nickName,this.phone)
      // },
      // loginCallBack() {
      //   this.checkLoginStatus();
      // },
      // storeUserInfo(nickName, phone) {
      //   localStorage.setItem('nickName', nickName);
      //   localStorage.setItem('phone', phone);
      // },
      // checkLoginStatus() {
      //   this.$api.get(`/get_customer_info`)
      //     .then(data => {
      //       if(data.code !== 0 && data.code !== 1) {
      //         this.$toast.error(data.msg);
      //         this.isUserLogin = false;
      //         return;
      //       }
      //       if(data.code === 1) {
      //         this.isUserLogin = false;
      //         return;
      //       }
      //       this.nickName = data.data.nickName;
      //       this.phone = data.data.phone;
      //       this.storeUserInfo(this.nickName, this.phone)
      //       this.isUserLogin = true;
      //     });
      // },
      // userLogin() {
      //   this.$modal.show(this.userloginModal,{})
      // },
      // userLogout(){
      //   if(this.isLogoutClick) {
      //     return;
      //   }
      //   let self = this;
      //   this.isLogoutClick = true;
      //   this.$api.post(`/logout`)
      //     .then(data => {
      //       self.isLogoutClick = false;
      //       if(data.code !== 0) {
      //         this.$toast.error(data.msg);
      //       }
      //       this.$toast.success('您已成功注销')
      //       this.logoutCallback();
      //     }, (err) => {
      //       this.$toast.error(err.msg);
      //       self.isLogoutClick = false;
      //     });
      // },
      getSearchParam: function(param) {
        this.searchKeyword = param;
        this.search();
      },
      search() {
        if (this.searchKeyword.length == 0)
          return;
        this.$router.push({
          name: 'productListBySearch',
          query: { keyword: this.searchKeyword, brands: this.brands },
        });
      },
      tangleSearchModal() {
        this.isSearchOpen = !this.isSearchOpen;
        this.$refs.mobileSearch.handleParentClick(this.isSearchOpen);
      },
      tangleMenuModal() {
        this.isMenuOpen = !this.isMenuOpen;
        this.$refs.mobileMenu.handleParentClick(this.isMenuOpen);
      },
      // tangleLoginModal() {
      //   this.isLoginOpen = !this.isLoginOpen;
      //   this.$refs.mobileLogin.handleParentClick(this.isLoginOpen);
      // },
      linkToDealer() {
        window.location.href = baseUrl.dealer;
      },
      linkToReg() {
        window.location.href = baseUrl.dealer + '#/register';
      },
      reloadPage(url, e){
        e.preventDefault();
        var currentUrl = this.$route.fullPath;
        let end_index = currentUrl.indexOf('?')>-1 ? currentUrl.indexOf('?') : currentUrl.length
        currentUrl = currentUrl.substring(0, end_index)
        if(currentUrl.endsWith(url)){
          let newUrl = url + '?_=' + new Date().getTime()
          this.$router.replace(newUrl);
        } else {
          this.$router.push(url)
        }
      }
    },
  }
</script>

<style scoped lang="sass">
   h1 { font-family: PingFangSC; font-size:2.4rem; font-weight:500; color:white; margin-top:14px}
   .forMobile {display: none}
   .flex1 {flex:1}
   .nav_a {
      display: flex; height:64px; color:#666; font-size:1.5rem;
      align-items: center;
      &:hover{color: #fa4c06; cursor: pointer;}
      &.on {color: #fa4c06; border-left:4px solid #fa4c06}
   }
   .to-login {width:170px; align-self:'flex-end'; margin-top:10px;}
   .regist {
     align-items:center;
     a {
       font-size:1.6rem;
       color: #ec2f00;
       &:hover{ text-decoration:underline}
     }
     span {color:#ec2f00}
   }
  .loginEntry{margin-left:20px;}
  .mobag-header {
    background-color: black;
    color: white;
    padding:10px 10px 20px 10px;
    .mobag-header-container{
      width: 100%;
      padding: 0 7%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      .mobag-menu-icon{
        width: 50px;
        font-size:24px;
        display:none;
      }
      img {
        width: 110px;
        margin-left: -10px;
        margin-top: -10px;
      }
      .mobag-search {
        flex: 1 1 auto;
        display:flex;
        margin: 0 0 0 10px;
        background-color: rgba(255,255,255,1);
        padding-left: 16px;
        align-items:center;
        input {
          height: 50px;
          width:80%;
          flex: auto;
          border: none;
          background-color: transparent;
          font-size:1.2rem;
          color: #666666;
        }
      }
      .mobag-nav {
        flex: 1 0 auto;
        display: flex;
        padding: 0 0 0 18px;
        a {
          padding: 20px;
          font-size: 1.6rem;
          color: white;
        }
      }
      .icon-search {
        display: inline-block;
        color:#666666;
        font-size:1.8rem;
        margin: 0 12px;
        cursor: pointer;
        &:active {color: #000000}
      }
      .mobag-search-mobile{display:none}
    }
    .dealer-login {
      text-align:right;
      padding: 0px 7% 0;
      a{
        color: white;
        font-size:1.2rem;
        line-height:2.4rem;
        cursor: pointer;
        .icon {margin-right:4px; width:1.2rem; height:1.2rem; margin-top:-3px;}
      }
    }
  }
  @media screen and (max-width: 767px) {
    .mobag-header {
      padding:8px;
      .mobag-header-container {
        padding: 0 10px;
        img{ width:110px; padding-top:10px; }
        .mobag-search {
          padding-left: 14px;
        }
        .mobag-nav {
          padding: 16px 0 0 10px;
          a{padding:10px;}
        }
      }
    }
  }

  @media screen and (max-width: 565px) {
    .loginEntry {margin-left:8px;}
    .mobag-header {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 9999;
      background-color: #000;
      padding:4px;
      .dealer-login {
        padding: 0px 2% 0;
      }
      .mobag-header-container {
        height:48px;
        .mobag-logo{
          flex:1 1 auto;
          justify-content: center;
          display:flex;
          img {
            width: 70px;
            padding-top:0;
          }
        }
        .mobag-search, .mobag-nav{
          display: none;
        }
        .mobag-menu-icon, .mobag-search-mobile{
          display:block
        }
      }
    }
    .forWeb {display: none}
    .forMobile {
      display: inline-block; cursor:pointer
    }
  }

</style>
