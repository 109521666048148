import VueI18n from 'vue-i18n'

var I18nMessage = {
  // 中文
  cn: {
    header: {
      homepage: 'Home',
      parts: 'Cabinet',
      helmets: '头盔',
      ridingGear: '服饰',
      accessories: '周边',
      allGears: 'Countertop',
      searchPlaceHolder: 'Search By Product Name',
      quotationPage: '选购指南',
      dealerLogin: 'Login'
    },
    searchByBike: {
      title: 'Search By Category',
      byMake: 'Category',
      bySeries: 'Type',
      byModel: 'Item',
      byYear: 'None',
    },
    footer: {
      instruction: 'Purchase Guide',
      howToBuy: 'How to Buy',
      faq: 'FQA',
      returnChange: 'Change & Return',
      company: 'Company',
      aboutUs: 'About Us',
      contactUs: 'Contact Us',
      policies: 'Policy',
      privacyStatement: 'Privacy Statement',
    },
    about: {
      howToBuy: [
        '请申请成为经销商，并登录经销商系统下单购买',
        '购买咨询联系人：',
      ],
      faq: {
        qaList: [
          [ '问：怎么才能快速的找到我想要的配件？', '答：如果您知道配件名称，可以直接在网站顶部搜索框输入名称搜索。您还可以通过【按车搜索】功能，通过输入您的爱车品牌，车型，车系等信息，浏览适配您爱车的所有配件' ],
          [ '问：如何购买下单？', '答：点击网站右上角申请成为经销商，登录经销商系统下单购买。' ],
          [ '问：发货走哪家物流？我会收到通知吗', '答：工作人员会根据您购买的物品选择合适的物流或快递公司，发货时您会收到通知和单号。' ],
          [ '问：如果商品有质量问题，如何处理？', '答：如果收货发现有质量问题，请第一时间联系我们的工作人员，进行退换货处理。' ],
          [ '问：如何能知道配件和装备的第一手信息？', '答：关注“摩燃”服务号， 我们会定期发布专业的机车资讯，改装教程，活动信息。（微信添加服务号，搜索“摩燃”）' ],
        ],
        purchaseAssistant: '购买咨询联系人：',
        wechat: '扫码关注摩燃服务号：',
      },
      returnChange: {
        text: '如果发现货物缺失或破损，请第一时间联系我们。',
        contactor: '退货换货咨询联系人：',
      },
      aboutUs: {
        infoList: [
          'Welcome to Best Value Kitchen!',
          'Best Value Kitchen is a kitchen cabinet and countertop design, fabrication, and installation company located in Farmingdale, Long Island, New York. We take pride in providing high-quality, cost-effective custom kitchen products.',
        ],
        vision: [
          '对我们而言，客户永远是第一位的，我们尽最大努力确保每位客户都能得到满意的服务。',
          '我们希望您不管是现在还是将来，都喜欢和我们一起向前『骑行』。',
          '我不想成为你的整个世界，只要是你世界里最好的那个。',
        ],
        signature: '—— 摩燃团队',
      },
      contact: {
        address: '地址：北京市朝阳区六里屯街道水堆子马道口186号 邮编：100026',
        email: '邮箱：',
      },
      privacyStatement: [
        '我们在隐私声明中使用的"个人信息"一词是指您个人直接或间接向 摩燃 提供的、可单独或与其他信息一起用于识别或表明您身份的信息。个人信息的例子包括：您的姓名、您的通讯地址、您的电话号码、您的电子邮件地址，或其他可用于识别或表明您身份的信息。',
        '我们承诺您的个人信息只会用于商品购买、物流发货和售后服务使用，我们不会向任何第三方提供您的信息。',
      ],
    },
  },

  // 英文
  en: {
    header: {
      homepage: 'HOME',
      parts: 'PARTS',
      helmets: 'HELMETS',
      ridingGear: 'RIDING GEAR',
      accessories: 'ACCESSORIES',
      allGears: 'RIDING GEAR',
      searchPlaceHolder: 'Search Product Name or Part Number',
      quotationPage: 'FEATURED',
      dealerLogin: 'DEALER LOGIN'
    },
    searchByBike: {
      title: 'Search By Bike',
      byMake: 'By Make',
      bySeries: 'By Series',
      byModel: 'By Model',
      byYear: 'By Year',
    },
    footer: {
      instruction: 'INSTRUCTION',
      howToBuy: 'HOW TO BUY',
      faq: 'FAQ',
      returnChange: 'EXCHANGE & RETURN',
      company: 'COMPANY',
      aboutUs: 'ABOUT US',
      contactUs: 'CONTACT US',
      policies: 'POLICIES',
      privacyStatement: 'PRIVACY STATEMENT',
    },
    about: {
      howToBuy: [
        'Please connect to our sales WeChat to purchase.',
        'Barcode of purchase assistant:',
      ],
      faq: {
        qaList: [
          [ 'Q: How to quickly find parts needed?', 'A: You can search them through the search bar on the top of homepage if you know the exact name. You can also use the function of “Search By Bike” to browse all the compatible parts by input the make, model, year of your bike.' ],
          [ 'Q: How to purchase when I find something amazing?', 'A: Please apply for dealership and then submit your order in the dealer portal.' ],
          [ 'Q: Who take charge of delivery and can I get informed?', 'A：Your order will be delivered through most suitable shipping method and you will get the tracking information after shipped.' ],
          [ 'Q: What can I do if I find some defects on the item?', 'A: Please contact our sales by WeChat ASAP and you can choose exchange or return according to policy.' ],
          [ 'Q: How to become a dealer of Moran?', 'A: Yes, please access https://dealer.mobag.cn for applying dealership' ],
          [ 'Q: How can I get the information of PARTS and ACCESSORIES at the earliest?', 'A: Please follow our public account in WeChat. We will publish professional motor information, customization manual, activities at intervals. (Search mobag_moto in WeChat official account)' ],
        ],
        purchaseAssistant: 'Barcode of purchase assistant:',
        wechat: 'Scan to follow our public account Moran in WeChat:',
      },
      returnChange: {
        text: 'If the package is missing or broken, please contact us by WeChat ASAP.',
        contactor: 'Barcode of Exchange & Return assistant:',
      },
      aboutUs: {
        infoList: [
          'Moran(mobag.cn) is a professional online service platform for parts and gears of motorbikes. We are engaging in providing the most professional and comfortable service and experience for dealers.',
          'Founded in Beijing, our staff locate in Beijing, Hangzhou, Qingdao, and the USA. From founders to employees, a lot of us are also riders. We know bikes and bikers as well. We hope to establish a legal and swift way to import and sale related items and provide dealers with best purchasing experience and product quality. No matter a novice or veteran, you will always find things belongs to you.',
        ],
        vision: [
          'For us, the satisfaction of our customers is always the highest priority. We are trying our best to provide every customer considerate service.',
          'We hope you can ride with us, shoulder by shoulder, no matter at present or in the future.',
          'We do not want to be your whole world, as long as the best in your world.',
        ],
        signature: 'By Moran TEAM',
      },
      contact: {
        address: 'No. 186, Shuiduizi Madaokou, Liulitun Street, Chaoyang, Beijing, China, 100026',
        email: 'Email: ',
      },
      privacyStatement: [
        'Moran is committed to your privacy and the protection of your Personal Data. It will only be used to purchase, delivery and service and will never be exposed to the third party.',
        'Personal Data in this statement are defined as any information that can be used independently or with other information together to identify an individual which is provided to Moran directly or indirectly. Personal Data include name, mailing address, telephone, Email and other information which can be used to identify an individual. ',
      ],
    },
  },
};

export default I18nMessage;
