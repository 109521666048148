<template>
  <div>
    <div class="introduction-bg">
      <div class="dialog-bg">
        <div class="title">
          <div class="bar"></div>
          <span class="en">Contact Us</span>
        </div>
        <p>
          If you have any inquiries, would like to request a quote, or need further information about Best Value Kitchen, please feel free to contact us:
        </p>
        <p>
          <b>Address:</b> 40 Gazza Blvd Unit D, Farmingdale, NY 11735<br>
          <b>Phone:</b> 917-907-0777<br>
          <b>Email:</b> bestvaluekitchenbath@gmail.com<br>
        </p>
      </div>
    </div>
    <div class="placeholder"></div>
  </div>
</template>

<script>
export default {
  created() {
    window.document.title = 'Best Value - Contact Us';
  }
}
</script>

<style scoped>
  .title {
    left: -210px;
  }
  .placeholder {height:25%;}
  @media (max-width:900px) {
    .introduction-bg {
      .title {
        left:0;
        top: -100px;
      }
    }
  }
</style>
