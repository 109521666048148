var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "padding-bottom": "100px" } },
    [
      _c("header", { staticClass: "mobag-header" }, [
        _c("div", { staticClass: "mobag-header-container" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "dealer-login" }, [
            _c(
              "a",
              { attrs: { id: "dealerLogin" }, on: { click: _vm.linkToDealer } },
              [
                _c("v-icon", {
                  staticClass: "icon",
                  attrs: { name: "pen-alt" }
                }),
                _vm._v("经销商登录")
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "dealer-login" }, [
            _c(
              "a",
              { attrs: { id: "dealerReg" }, on: { click: _vm.linkToReg } },
              [
                _c("v-icon", { staticClass: "icon", attrs: { name: "store" } }),
                _vm._v("成为经销商")
              ],
              1
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { "text-align": "center", margin: "50px 0 0 0" } },
        [
          _c("p", { staticClass: "title-size" }, [
            _vm._v(_vm._s(_vm.sharePageInfo.title))
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main-list" },
        [
          _c("b-table", {
            staticStyle: { "text-align": "center", "font-size": "16px" },
            attrs: {
              items: _vm.productList,
              fields: _vm.fields,
              emptyText: _vm.emptyText,
              striped: "",
              "show-empty": ""
            },
            scopedSlots: _vm._u([
              {
                key: "cell(image)",
                fn: function(data) {
                  return [
                    _c("div", [
                      _c(
                        "a",
                        {
                          staticStyle: { cursor: "pointer" },
                          on: {
                            click: function($event) {
                              return _vm.tangleImgEnlarged(data.item.image)
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.operaImg(data.item.image),
                              onerror:
                                "this.src='https://cdn.mobag.cn/9dc79963d75b6b8785c47caf8de091a3?imageView2/2/w/100/h/100'"
                            }
                          })
                        ]
                      )
                    ])
                  ]
                }
              },
              {
                key: "cell(brandAndPartNumber)",
                fn: function(data) {
                  return [
                    _c("div", { staticClass: "center" }, [
                      _c("span", { staticStyle: { "font-weight": "bolder" } }, [
                        _vm._v(_vm._s(data.item.brand))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { "margin-top": "5px" } }, [
                      _c("span", [_vm._v(_vm._s(data.item.partNumber))])
                    ])
                  ]
                }
              },
              {
                key: "cell(description)",
                fn: function(data) {
                  return [
                    _c("div", { staticClass: "center" }, [
                      _c("p", [_vm._v(_vm._s(data.item.description))])
                    ])
                  ]
                }
              },
              {
                key: "cell(currentPrice)",
                fn: function(data) {
                  return [
                    _c("div", { staticClass: "center" }, [
                      _c("p", [_vm._v(_vm._s(data.item.currentPrice / 100))])
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("img-enlarged", {
        ref: "imgEnlarged",
        on: {
          tangle: function($event) {
            return _vm.tangleImgEnlarged()
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main-list-mobile" },
        [
          _vm._l(_vm.productList, function(item, index) {
            return _c("mobile-list-item", {
              key: index,
              staticClass: "mobileItems",
              attrs: { item: item }
            })
          }),
          _vm._v(" "),
          _c(
            "p",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.productList.length === 0,
                  expression: "productList.length===0"
                }
              ],
              staticClass: "emptyForMobile",
              staticStyle: { "font-size": "1.2rem" }
            },
            [_vm._v(_vm._s(_vm.emptyText))]
          )
        ],
        2
      ),
      _vm._v(" "),
      _c("header", { staticClass: "mobag-header" }, [
        _c(
          "div",
          {
            staticClass: "mobag-header-container",
            staticStyle: { height: "18rem" }
          },
          [
            _vm.salesQrCode
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      color: "#fff",
                      "font-size": "16px",
                      margin: "5px auto",
                      "text-align": "center"
                    }
                  },
                  [
                    _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
                      _vm._v("扫描二维码咨询购买")
                    ]),
                    _vm._v(" "),
                    _c("img", {
                      staticStyle: { width: "12rem" },
                      attrs: { src: _vm.salesQrCode, alt: "" }
                    })
                  ]
                )
              : _vm.pageTitle
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      color: "#fff",
                      "font-size": "16px",
                      margin: "5px auto",
                      "text-align": "center"
                    }
                  },
                  [
                    _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
                      _vm._v("扫描二维码咨询购买")
                    ]),
                    _vm._v(" "),
                    _c("img", {
                      staticStyle: { width: "12rem" },
                      attrs: {
                        src:
                          "https://cdn.mobag.cn/c6cdd3b0bc45fcbda2d063db75aa652d",
                        alt: ""
                      }
                    }),
                    _vm._v(" "),
                    _c("img", {
                      staticStyle: { "margin-left": "20px", width: "12rem" },
                      attrs: {
                        src:
                          "https://cdn.mobag.cn/8ccf8f87780f9091f76d867c0f315d11",
                        alt: ""
                      }
                    })
                  ]
                )
              : _vm._e()
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mobag-logo" }, [
      _c("img", { attrs: { src: "https://cdn.mobag.cn/BestValueLogo.png" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }