<template>
  <div style="background-color:#fafafa;">
		<b-container class="b-container" fluid style="background-color:#fff;">
			<b-row class="top-container">
				<b-col md="6" lg="6" sm="12">
					<div class="bd">
            <video class="video" controls :src="headImgItem.url" v-if="headImgItem.kind == 0">
            </video>
						<img style="height: 240px;" v-show="headImgItem.kind == 1" :src="headImgItem.url" @click="showBigImageModal(headImgItem.url)">
					</div>
					<div class="hd">
							<div class="spic" v-for="(headImg, index) in showMediaList"
									:class="{ on: index === selectedHeadImgIndex }"
									@mouseover="selectedHeadImgIndex = index" :key="index">
								<img :src="headImg.url" v-if="headImg.kind == 1">
                <div class="video-img-box" v-else>
                  <img src="../../images/play.png" class="video-play-icon"/>
                  <img :src="getVideoImg(index)" />
                </div>
							</div>
					</div>
				</b-col>
				<b-col class="top-right" md="6" lg="6" sm="12">
          <h2 v-if="product.chineseTitle" class="font18 fw600">{{ product.chineseTitle }}</h2>
					<h2 v-else class="font18 fw600">{{ product.title }}</h2>
          <br>
					<!--span class="font24 fw500 red price pb40">Retail Price：$ {{ (minCurrentPrice / 100.0).toFixed(2) }}{{ minCurrentPrice===maxCurrentPrice ? null : '-'+(maxCurrentPrice / 100.0).toFixed(2)}}</span-->
					<div v-if="product.partNumber != ''" class="font14">SKU：{{product.partNumber}}</div><br>
          <div v-if="product.inventory === 0" class="font14">Out of Stock</div><br>
          <div v-if="product.inventory > 0" class="font14">In Stock</div><br>
          <div v-for="specName,specType in $bizCode.SpecType"
							 v-show="specType > 0 && existedSpecIdList[specType].length > 0"
							 class="selection-container" :key="specType">
						<span class="name">{{ specName }}</span>
						<div class="s-right">
							<div v-for="spec in filterSpecList(specType)"
									v-show="existedSpecIdList[specType].includes(spec.id)"
									@click="selectSpec(spec.id, specType)"
                  :key="spec.id"
									:class="{ 'on': spec.id === selectedSpecIdList[specType] }"
									:style="{ color: canSelectSpecIdList.length === 0
								|| canSelectSpecIdList.includes(spec.id)
								|| spec.id === selectedSpecIdList[specType] ? '#000' : '#F1F1F1' }
								"
									class="font12 sitem"
							>
								{{ spec.title }}
							</div>
						</div>
					</div>
					<button class="btn-buy font18 fw600">
            <!--a style="color: #fff" href="https://dealer.mobag.cn"></a-->
            <router-link  style="color: #fff" to="/about/how_2_buy">How to Buy</router-link>
          </button>
          <!--button class="btn-buy font18 fw600" @click="show">我要咨询</button-->
				</b-col>
			</b-row>
		</b-container>

		<b-container>
			<b-row class="bottom-container">
				<b-col>
					<div class="details-main" v-html="product.description"></div>
				</b-col>
			</b-row>
      <b-row class="bottom-container-recommend">
        <b-col>
          <div class="recommend" v-if="hasRelatedProducts">
            <h4 class="recommend-title">相关推荐：</h4>
            <div class="recommend-list">
              <div class="recommend-item" v-for="(item,index) in product.relatedProducts" :key="index" @click="showProductDetail(item)">
                <div class="relative">
                  <img :src="item.headImgList[0] && item.headImgList[0].url" class="recommend-image">
                </div>
                <div class="recommend-info-box">
                  <h5>{{item.chineseTitle || item.title}}</h5>
                  <div class="recommend-number" v-show="item.partNumber">配件编号: {{item.partNumber}}</div>
                  <div class="recommend-number" v-show="item.dragNumber">Drag编号: {{item.dragNumber}}</div>
                </div>
              </div>
              <div class="recommend-item empty-box" v-if="product.relatedProducts.length % 3 == 2"></div>
            </div>
          </div>
        </b-col>
      </b-row>
		</b-container>

	  <b-modal id="qrcode"
             :header-bg-variant="headerBgVariant"
             :header-text-variant="headerTextVariant"
             :body-bg-variant="bodyBgVariant"
             :body-text-variant="bodyTextVariant"
             size="lg" @ok="hide" hide-footer
             >
		  <template #modal-title>
        <div style="font-size: 24px;padding: 10px; color: #fff">添加客服</div>
      </template>
		  <div class="modal_body">
			  <p>请用微信扫描下面的二维码，添加客服</p>
        <img :src="randomeQrImage"/>
		  </div>
      <a class="button-lg-red mt60 font16" style="color:#fff" @click="hide">确定</a>
	  </b-modal>
    <b-modal id="bigImage" :hide-footer="true" size="lg">
      <div>
        <img :src="bigImage" class="big-img">
      </div>
    </b-modal>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'
import {
  getRandomSelQrCode
} from '../../common/Util'

export default {
  data: function() {
    return {
      productId: 0,
      product: {},
      productSpecSelected: null,

      specList: [],
      productSpecList: [],
      existedSpecIdList: [],  // 二维数组，一维是specType
      selectedSpecIdList: [], // 一维数组
      canSelectSpecIdList: [],  // 不在这里面的都为灰色

      specIdMap: {}, // 存储每个specId对应其他类型可选的specIdList
      sharePageInfo: {},
      headImgList: [],
      minCurrentPrice: 0,
      maxCurrentPrice: 0,
      selectedHeadImgIndex: null,
      showModal: false,
      headerBgVariant: 'dark',
      headerTextVariant: 'light',
      bodyBgVariant: 'light',
      bodyTextVariant: 'dark',
      footerBgVariant: 'warning',
      footerTextVariant: 'dark',
      randomeQrImage: '',
      weChatParamsUrl: '/get_wechat_share_params',
      bigImage: ''
    }
  },
  created: function() {
    this.productId = this.$route.params.id;
    for (let i of this.$bizCode.SpecType) {
      this.existedSpecIdList.push([]);
      this.selectedSpecIdList.push(null);
    }

    this.$api.get(`/get_product_detail?id=${this.productId}`)
      .then(data => {
        // console.log(JSON.stringify(data))
        this.product = data.data;
        window.document.title = this.product.chineseTitle?this.product.chineseTitle:this.product.title;
        this.headImgList = this.product.headImgList;
        this.selectedHeadImgIndex = 0;
        this.minCurrentPrice = this.maxCurrentPrice = this.product.currentPrice;
        this.sharePageInfo.title = this.product.chineseTitle?this.product.chineseTitle:this.product.title;
        this.sharePageInfo.shareImage = this.headImgList.length>0?this.headImgList[0].url:'https://cdn.mobag.cn/logo.png'
        this.sharePageInfo.shareDescription = this.product.description.replace( /(<([^>]+)>)/ig, '');
        this.initWechat();
        if (this.product && this.product.relatedProducts) {
            let productList = this.product.relatedProducts
            for (let i = 0; i < productList.length; i += 1) {
              const filterImages = productList[i].headImgList.filter(item => item.kind === 1)
              productList[i].headImgList = filterImages
            }
            this.product.relatedProducts = productList
          }
      });

    this.$api.get(`/query_spec_list?product_id=${this.productId}`)
      .then(data => {
        this.specList = data.data.specList;
      });

    this.$api.get(`/query_product_spec_list?product_id=${this.productId}`)
      .then(data => {
        this.productSpecList = data.data.productSpecList;
        for (let productSpec of this.productSpecList) {
          for (let specType = 0; specType < productSpec.specIdList.length; ++specType) {
            let specId = productSpec.specIdList[specType];
            if (specId === 0)
              continue;
            // 记录所有存在的规格
            this.existedSpecIdList[specType].push(specId);
            // 记录规格对应关系
            if (! (specId in this.specIdMap)) {
              this.specIdMap[specId] = [];
            }
            this.specIdMap[specId] = this.specIdMap[specId].concat(productSpec.specIdList.filter(x => x!==0 && x!=specId));
            // 设置最小最大价格
            if (productSpec.currentPrice !== 0 && productSpec.currentPrice < this.minCurrentPrice)
              this.minCurrentPrice = productSpec.currentPrice;
            if (productSpec.currentPrice !== 0 && productSpec.currentPrice > this.maxCurrentPrice)
              this.maxCurrentPrice = productSpec.currentPrice;
          }
        }
        this.canSelectSpecIdList = this.existedSpecIdList.reduce((x,y) => x.concat(y));
      });
  },
  computed: {
    headImgItem() {
      // 由于有这个判断，video需要用v-if，v-show由于一开始没有资源后续video也不会自动初始化，就无法加载视频
      if(this.selectedHeadImgIndex == null) {
        return {kind: 1, url: ''}
      }
      return this.showMediaList[this.selectedHeadImgIndex]
    },
    // videoImg() {
      // return this.headImgList.find(item => item.kind == 1)
    // },
    showMediaList() {
      return this.videoList.concat(this.imgsList).slice(0, 5)
    },
    videoList() {
      if(this.headImgList) {
        return this.headImgList.filter(item => item.kind === 0)
      }
      return []
    },
    imgsList() {
      if(this.headImgList) {
        return this.headImgList.filter(item => item.kind === 1)
      }
      return []
    },
    imgsListLength() {
      return this.imgsList.length
    },
    hasRelatedProducts() {
      return this.product.relatedProducts && this.product.relatedProducts.length;
    },
  },
  methods: {
    initWechat() {
      this.$api.post(this.weChatParamsUrl,{'current_url': String(window.location)})
        .then(data => {
          // console.log(JSON.stringify(data))
          wx.config({
            debug: false,
            appId: data.data.appId,
            timestamp: data.data.timestamp,
            nonceStr: data.data.nonceStr,
            signature: data.data.signature,
            jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage']
          })
          let share_title = this.sharePageInfo.title;
          let share_link = String(window.location);
          let share_img = this.sharePageInfo.shareImage;
          let share_desc = this.sharePageInfo.shareDescription;
          wx.ready(function () {
            var options = {
                title: share_title,
                link: share_link,
                imgUrl: share_img,
                desc: share_desc,
                success: function () {
                    console.info('分享成功');
                },
                cancel: function () {
                    console.info('取消分享');
                }
            };
            wx.onMenuShareTimeline(options);
            wx.onMenuShareAppMessage(options);
          });
      });
    },
    showProductDetail(item) {
      let routerData = this.$router.resolve({name: 'productDetail', params: {id: item.productId}})
      window.open(routerData.href, '_blank')
    },
    show () {
      this.randomeQrImage = getRandomSelQrCode()
      this.$bvModal.show('qrcode');
    },
    hide () {
      this.$bvModal.hide('qrcode');
    },
    getVideoImg(index) {
      let item = this.imgsList[index % this.imgsListLength]
      return item && item.url
    },
    filterSpecList: function(specType) {
      return this.specList.filter(x => x.specType===specType);
    },
    showBigImageModal(url) {
      this.bigImage = url;
      this.$bvModal.show('bigImage');
    },
    selectSpec: function(specId, specType) {
      if ( !this.canSelectSpecIdList.includes(specId)) {
        return;
      }

      if (this.selectedSpecIdList[specType] === specId) {
        this.selectedSpecIdList.splice(specType, 1, null);  // 反选
      } else {
        this.selectedSpecIdList.splice(specType, 1, specId);  // 正选
      }
      this.canSelectSpecIdList = [];
      this.productSpecSelected = null;
      this.headImgList = this.product.headImgList;

      for (let productSpec of this.productSpecList) {
        // 如果当前所有选中的规格都匹配某个商品的规格列表
        if (this.selectedSpecIdList
          .flatMap(x => x === null || productSpec.specIdList.includes(x))
          .reduce((x,y) => x & y)
        ) {
          // 设置最小最大价格
          if (productSpec.currentPrice !==0 && productSpec.currentPrice < this.minCurrentPrice)
            this.minCurrentPrice = productSpec.currentPrice;
          if (productSpec.currentPrice !==0 && productSpec.currentPrice > this.maxCurrentPrice)
            this.maxCurrentPrice = productSpec.currentPrice;

          // 反过来，商品的规格和所选规格都相同，说明选中了一个子商品，设置productSpecSelected
          if (productSpec.specIdList
            .flatMap(x => x === 0 || this.selectedSpecIdList.includes(x))
            .reduce((x,y) => x & y)
          ) {
            this.productSpecSelected = productSpec;
            this.selectedHeadImgIndex = 0;
            this.headImgList = productSpec.headImgList.length === 0 ? this.product.headImgList : productSpec.headImgList;
            this.minCurrentPrice = this.maxCurrentPrice = productSpec.currentPrice === 0 ? this.product.currentPrice : productSpec.currentPrice;
          }

          // 求交集得出canSelectSpecIdList
          for (let id of this.selectedSpecIdList.filter(x => x !== null)) {
            this.canSelectSpecIdList = this.canSelectSpecIdList.length === 0 ?
              this.specIdMap[id] : this.canSelectSpecIdList.concat(this.specIdMap[id]);
          }
        }
      } // end for

      // 如果只选了一个规格，同类型规格也都可选
      if (this.selectedSpecIdList.filter(x => x !== null).length === 1) {
        this.canSelectSpecIdList = this.canSelectSpecIdList.concat(this.existedSpecIdList[this.selectedSpecIdList.findIndex(x => x !== null)]);
      }

      // 如果没有选择任何规格
      if (this.selectedSpecIdList.filter(x => x !== null).length === 0) {
        this.canSelectSpecIdList = this.existedSpecIdList.reduce((x,y) => x.concat(y));
      }
    },
  }
}
</script>

<style scoped lang="scss">
	.modal_body {text-align:center; max-height:500px;z-index: 0}
	.modal_body img{ height:200px; width:200px; margin: 20px 40px 20px 40px;}
  .modal-backdrop {display: none}
  .b-container{padding-top: 40px;}
	.top-container {
		max-width:1200px;
		background-color:#fff;
		margin: 0 auto;
		padding-top:20px;
		padding-bottom:20px;
	}
	.bottom-container {
		max-width:1200px;
		margin: 40px auto 0;
		padding:40px;
		border-top:2px solid #ec2f00;
		background-color:#fff;
	}
  .bottom-container-recommend {
		max-width:1200px;
		margin: 40px auto 0;
		padding:40px;
		border-top:2px solid #ec2f00;
		background-color:#fff;
	}
	.bd {
		border:1px solid #ececec; padding:19px; display:flex; justify-content: center; align-items:center;
		img { max-width:100%; max-height:100%;cursor: pointer;}
	}
  .video { height: 240px;max-width: 100%; }
  .big-img { display: block; width: 100%;}
	.hd{
    display:flex;
		justify-content: flex-start;
		align-items:center;
		margin-top:18px;
		.spic {
      width:83px;
			height:83px;
			border:1px solid #ececec;
			margin: 0 16px 0 0;
			flex: 0 0 auto;
			overflow:hidden;
			padding:5px;
			img {width:100%; height:100%;}
      .video-img-box {
        position: relative;
      }
      .video-play-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
		}
	}
	.top-right{
		padding-left:48px;
	  .price {margin-top:10px; display:block;}
	}
	.selection-container {
		display:flex;
		align-items: center;
	  margin-bottom:8px;
		.s-right {
			display:flex; align-items:center; justify-content: flex-start; flex-wrap: wrap;
		  .sitem {border: 1px solid #ccc; line-height:32px; padding:0 18px; margin: 8px; cursor:pointer}
			.on {border:1px solid #ec2f00;}
	  }
		span {flex: 0 0 auto;}
	  .name{
			color:#333;
			font-size:1.2rem;
			padding: 0 20px 0 0;
			align-self: flex-start;
			margin-top:15px;}
	}
	.btn-buy{
		width: 146px;
		height: 44px;
		background-color: #ec2f00;
		color: #fff;
		line-height:1.6rem;
		text-align:center;
		border:0;
		margin-top:40px;
	}
  .recommend {
    text-align: left;
    padding: 0 20px;
    background: #fff;
    margin: 10px;
    overflow: hidden;
    &-list {
      justify-content: space-between;
      display: flex;
      flex-wrap: wrap;
    }
    &-title {
      color: #333A41;
      font-size: 1.4rem;
      margin: 10px 0;
      font-weight: 600;
    }
    &-item {
      display: flex;
      min-height: 100px;
      margin: 15px 0;
      cursor: pointer;
      width: 32%;
    }
    .relative {
      position: relative;
    }
    .play-img {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      width: 30px;
      height: 30px;
      margin: 0 auto;
      transform: translateY(-50%);
      z-index: 1;
    }
    &-image {
      width: 100px;
      min-width: 100px;
    }
    &-info-box {
      position: relative;
      margin-left: 15px;
      flex: 1;
      font-size: 1.4rem;
      overflow: hidden;
    }
    h5 {
      font-weight: 500;
      color: #181D26;
      line-height: 20px;
      height: 20px;
      margin: 0 0 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-number {
      font-size: 1.2rem;
    }
    &-price-box {
      position: absolute;
      bottom: 5px;
      height: 20px;
      line-height: 20px;
      .price {
        font-weight: 500;
        color: #E75A2D;
        font-family: "teko";
        font-size: 2rem;
        position: relative;
        top: 1px;
      }
      .text {
        color: #A3A9B7;
        margin-left: 15px;
      }
    }
    &-cart-box {
      position: absolute;
      bottom: 0;
      right: 0;
      color: #FF3500;
      border: 1px solid #FF3500;
      width: 88px;
      height: 30px;
      line-height: 26px;
      text-align: center;
      font-size: 1rem;
      .icon {
        width: 12px;
        margin-right: 5px;
        vertical-align: middle;
      }
      span {
        vertical-align: middle;
      }
      &:hover {
        color: #fff;
        background: #FF3500;
      }
    }
  }

  @media screen and (max-width:1050px) {
    .recommend {
        &-item {
          width: 100%;
        }
        .empty-box {
          display: none;
        }
      }
  }

	@media (max-width:767px){
		.top-right { padding-top:20px; padding-left:15px; }
    .b-container{padding-top: 40px;}
		.bd {
			width: auto; height:auto; padding:15px;
			img{height:auto}
		}
		.hd ul li {width:60px; height:60px}
		.btn-buy {margin-bottom:20px;}
		.bottom-container { padding: 12px 0;}
    .mobile-hide {display: none}
    .recommend {
      &-item {
        width: 100%;
      }
      .empty-box {
        display: none;
      }
    }
	}

	@media (max-width: 575px) {
		.selection-container .s-right .sitem {overflow: hidden; text-overflow: ellipsis; white-space:nowrap;}
    .b-container{padding-top: 80px;}
	}
</style>
<style>
	.details-main img {max-width:100%;}
</style>
