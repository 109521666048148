<template>
  <div class="cat-block">
    <div v-for="(cat,index) in catList" :key="cat.id" class="cat-container">
      <b-button v-b-toggle="cat.name"
                variant="outline-primary"
                class="cat-title">
        <span class='text' style="font-size: 14px;font-weight: bolder;">{{cat.name}}</span>
        <v-icon v-if="cat.visible" name="minus" class="symble" width="1rem" height="1rem"/>
        <v-icon v-else name="plus" class="symble" width="1rem" height="1rem"/>
      </b-button>
      <b-collapse visible :id="cat.name" v-model="cat.visible" :class="[{'brand-collapse':cat.name == '品牌'},{'brand-collapse-down':scrollFlag}]">
        <div class="search-view" v-if="cat.name == '品牌'">
          <input v-model="brandKeyword" placeholder="品牌搜索"/>
          <v-icon name="search" class="search-icon"></v-icon>
        </div>
        <b-card :class="[{'brand-box':cat.name == '品牌'},{'brand-box-down':scrollFlag}]" @scroll="scrollHandler()" :ref="'box'+cat.id">
          <b-form-checkbox v-model="mySelected"
                           class="card-text" style="font-size: 14px;font-weight: bolder;"
                           :key="option.id"
                           :value="option.id"
                           v-for="option in getChildren(cat)">
                           {{ option.name }}
          </b-form-checkbox>
        </b-card>
      </b-collapse>
    </div>
  </div>
</template>

<script>
/*
{
  children: [
    {
      id: 10
      name: "尾段"
    }
  ]
  id: 1
  name: "排气"
}
*/
export default {
  props: {
    list: Array,
    selected: Array,
    collaped: 0
  },
  data: function() {
    return{
      catList: [],
      mySelected: this.selected,
      brandKeyword: '',
      scrollFlag: false,//false会显示半透明遮罩
    }
  },
  watch: {
    list: function(newList, oldList) {
      this.catList = [];
      let isVisible = true;
      this.collaped = 0
      // if(newList && newList.length < 30){
      //   this.collaped = 0
      // }
      // if(this.collaped == 1)
      //   isVisible = false;
      for (let cat of newList) {
        // 检测有选中的默认展开卡片
        this.catList.push({
          id: cat.id,
          name: cat.name,
          visible:true,
          // visible: cat.children.some(child=>{
          //   return this.selected.includes(`${child.id}`)
          // }),
          children: cat.children,
        })
      }
    },
    mySelected: function(newValue, oldValue) {
      // single selection
      if(newValue.length > 1){
        newValue = [newValue[newValue.length-1]]
      }
      this.mySelected = newValue
      if(newValue.length <= 1 && oldValue.length <= 1){
        this.$emit('onSelectChange', {
            value: newValue
          });
      }

    },
    selected(newVaule) {
      this.mySelected = newVaule;
    },
    brandList(val) {
      // 视图更新后重新检查滚动条情况
      this.$nextTick(()=>{
        this.scrollHandler()
      })
    }
  },
  computed: {
    brandObj() {
      return this.catList.filter((item)=>{
        return item.name == '品牌'
      })
    },
    brandList() {
      if(this.brandObj.length == 0) {
        return []
      }
      // 过滤大小写不敏感
      return this.brandObj[0].children.filter((item)=>{
        return item.name.toLowerCase().includes(this.brandKeyword.toLowerCase())
      })
    }
  },
  methods: {
    getChildren(cat) {
      if(cat.name == '品牌') {
        return this.brandList
      }
      return cat.children
    },
    scrollHandler() {
      if(this.$refs['box-1']) {
        // v-for中返回的是数组
        const brandRef = this.$refs['box-1'][0]
        // 当滚动条滑倒底部或没有滚动条时，设置true，隐藏遮罩
        if(brandRef.scrollHeight-brandRef.clientHeight-brandRef.scrollTop <= 1) {
          this.scrollFlag = true
        } else if(this.scrollFlag) {
          this.scrollFlag = false
        }
      }
    },
  },
}
</script>
<style lang="sass" scoped>
.btn-outline-primary,
.btn-outline-primary:focus, .btn-outline-primary.focus,
.btn-outline-primary:hover, .btn-outline-primary.hover,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus
{color: #333; border:0; box-shadow:none; background-color:transparent}
.btn-outline-primary:hover, .btn-outline-primary.hover {color: #ec2f00}
.card-text {padding-bottom:0.5rem; padding-left:1.1rem}
.card{border:0; background:transparent;box-shadow: none}
.cat-title {
  font-size:1.6rem; display:flex; width:100%; text-align:left; padding:0
}
.symble {width:20px; align-self:center}
.text {flex:1;}
.card-body {padding-top:0.6rem; font-size:1.2rem; line-height:2.4rem}
.expanded .plus {display: none }
.expanded .minus {display: block}
.collapsed .plus {display:block}
.collapsed .minus {display: none}
.cat-container {margin-bottom:1rem}
.cat-block {margin-bottom:4 rem}
.custom-control-label {font-size: 14px;font-weight: bolder;}
.brand-collapse {
  position: relative;
  border-bottom: 1px solid #c7c7c6;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 70px;
    background: linear-gradient(rgba(255,255,255,0),#fafafa);
    pointer-events: none;
    opacity: 1;
    transition: opacity .2s linear;
    z-index: 1;
  }
}
.search-view {
  position: relative;
  margin: 1rem 0 .5rem;
  color: #333;
  input {
    border: 1px solid #c7c7c6;
    outline: none;
    display: block;
    font-size: 1.4rem;
    height: 4rem;
    padding: 0 3.5rem 0 1rem;
    line-height: 4rem;
    width: 100%;
  }
  .search-icon {
    position: absolute;
    right: 1rem;
    top: 1.1rem;
    cursor: pointer;
  }
}
.brand-box {
  max-height: 350px;
  overflow-y: auto;
  padding-bottom: 1rem;
  margin-top: 1rem;
}
.brand-collapse-down{
  &::after {
    opacity: 0;
  }
}
@media screen and(max-width:767px) {
  .cat-title {
    font-size:1.4rem; display:flex; width:100%; text-align:left
  }
  .custom-control-label {font-size: 14px;font-weight: bolder;}
}
</style>
