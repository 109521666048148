<template>
  <div class="search-in" id="webSearchByBike">
    <div class="dp-container">
      <b-dropdown :text="selectedBrandName" class="dp-item">
        <b-dropdown-item @click="saveSelection('brand', brand.id, brand.name)"
                         v-for="brand in showBrandList"
                         :value="brand.id"
                         :key="brand.id"
        >{{brand.name}}</b-dropdown-item>
      </b-dropdown>
      <v-icon name="angle-down" class="icon" scale="1.4" />
    </div>
    <div class="dp-container">
      <b-dropdown :text="selectedSeriesName" class="dp-item">
        <b-dropdown-item @click="saveSelection('series', series.id, series.name)"
                         v-for="series in showSeriesList"
                         :value="series.id"
                         :key="series.id"
        >{{series.name}}</b-dropdown-item>
      </b-dropdown>
      <v-icon name="angle-down" class="icon" scale="1.4" />

    </div>
    <div class="dp-container">
      <b-dropdown :text="selectedModelName" class="dp-item">
        <b-dropdown-item @click="saveSelection('model', model.id, model.name)"
                         v-for="model in showModelList"
                         :value="model.id"
                         :key="model.id"
        >{{model.name}}</b-dropdown-item>
      </b-dropdown>
      <v-icon name="angle-down" class="icon" scale="1.4"/>

    </div>
<!--    <div class="dp-container">-->
<!--      <b-dropdown :text="selectedYearName" class="dp-item">-->
<!--        <b-dropdown-item @click="saveSelection('year', year, year)"-->
<!--                         v-for="year in showYearList"-->
<!--                         :value="year"-->
<!--                         :key="year"-->
<!--        >{{year}}</b-dropdown-item>-->
<!--      </b-dropdown>-->
<!--      <v-icon name="angle-down" class="icon" scale="1.4"/>-->
<!--    </div>-->
    <b-button @click="search" class="btn-search" v-bind:disabled="searchButtonDisabled">GO</b-button>
  </div>
</template>

<script>
export default {
  props:{
    currentBikeSelection: Array
  },
  data: function() {
    return {
      motoModelList: [],
      showBrandList: [],
      showSeriesList: [],
      showModelList: [],
      showYearList: [],
      selectedBrandId: null,
      selectedSeriesId: null,
      selectedModelId: null,
      selectedYear: null,
      selectedBrandName: this.$t('searchByBike.byMake'),
      selectedSeriesName: this.$t('searchByBike.bySeries'),
      selectedModelName: this.$t('searchByBike.byModel'),
      selectedYearName: this.$t('searchByBike.byYear'),
      searchButtonDisabled: true,
      bikeSelection: this.currentBikeSelection
    }
  },
  created: function() {
    this.searchButtonDisabled = true
    for (let i = (new Date()).getFullYear() + 1; i > 2010; i--) {
      this.showYearList.push(i);
    }
    this.$api.get('/query_moto_model_list')
      .then(data => {
        this.motoModelList = data.data.motoModelList;
        for (let motoModel of this.motoModelList) {
          if (this.showBrandList.findIndex(x => { return x.id == motoModel.motoBrandId }) == -1)
            this.showBrandList.push({
              id: motoModel.motoBrandId,
              name: motoModel.motoBrandName,
            });
        }
      });
  },
  watch: {
    currentBikeSelection(newValue, oldValue) {
      this.bikeSelection = newValue;
      if(this.bikeSelection!=undefined && this.bikeSelection[0]>0 && this.bikeSelection[1]>0 && this.bikeSelection[2]>0){
        this.$api.get('/query_moto_model_list')
        .then(data => {
          this.motoModelList = data.data.motoModelList;
          for (let motoModel of this.motoModelList) {
            if (this.showBrandList.findIndex(x => { return x.id == motoModel.motoBrandId }) == -1)
              this.showBrandList.push({
                id: motoModel.motoBrandId,
                name: motoModel.motoBrandName,
              });
          }

          this.showSeriesList = [];
          for (let motoModel of this.motoModelList.filter(x => { return x.motoBrandId == this.bikeSelection[0] })) {
            if (this.showSeriesList.findIndex(x => { return x.id == motoModel.motoSeriesId }) == -1)
              this.showSeriesList.push({
                id: motoModel.motoSeriesId,
                name: motoModel.motoSeriesName,
              });
          }
          this.selectedBrandId = this.bikeSelection[0]
          for (const brandObj of this.showBrandList){
            if(brandObj.id == this.selectedBrandId){
              this.selectedBrandName = brandObj.name
              break;
            }
          }

          this.showModelList = [];
          for (let motoModel of this.motoModelList.filter(x => { return x.motoSeriesId == this.bikeSelection[1] })) {
            this.showModelList.push({
              id: motoModel.id,
              name: motoModel.name,
            });
          }

          this.selectedSeriesId = this.bikeSelection[1]
          for (const seriesObj of this.showSeriesList){
            if(seriesObj.id == this.selectedSeriesId){
              this.selectedSeriesName = seriesObj.name
            }
          }

          this.selectedModelId = this.bikeSelection[2]
          for (const modelObj of this.showModelList){
            if(modelObj.id == this.selectedModelId){
              this.selectedModelName = modelObj.name
            }
          }
        });
      }
    },
    selectedBrandId: function(newSelectedBrandId, oldSelectedBrandId) {
      this.showSeriesList = [];
      for (let motoModel of this.motoModelList.filter(x => { return x.motoBrandId == newSelectedBrandId })) {
        if (this.showSeriesList.findIndex(x => { return x.id == motoModel.motoSeriesId }) == -1)
          this.showSeriesList.push({
            id: motoModel.motoSeriesId,
            name: motoModel.motoSeriesName,
          });
      }
    },
    selectedSeriesId: function(newSelectedSeriesId, oldSelectedSeriesId) {
      this.showModelList = [];
      for (let motoModel of this.motoModelList.filter(x => { return x.motoSeriesId == newSelectedSeriesId })) {
        this.showModelList.push({
          id: motoModel.id,
          name: motoModel.name,
        });
      }
    },
  },
  methods: {
    saveSelection(type, id, name) {
      switch(type){
        case 'brand':
          this.selectedBrandId = id;
          this.selectedBrandName = name;
          this.clear('series');
          break;
        case 'series':
          this.selectedSeriesId = id;
          this.selectedSeriesName = name;
          this.clear('model');
          break;
        case 'model':
          this.selectedModelId = id;
          this.selectedModelName = name;
          this.clear('year');
          break;
        case 'year':
          this.selectedYear = id;
          this.selectedYearName = name;
          break
      }
      this.searchStatusCheck();
    },
    clear(behind) {
      switch(behind){
        case 'series':
          this.selectedSeriesId = null;
          this.selectedSeriesName = null;
          this.selectedModelId = null;
          this.selectedModelName = null;
          this.selectedYear = null;
          this.selectedYearName = null;
          this.selectedSeriesName = this.$t('searchByBike.bySeries');
          this.selectedModelName = this.$t('searchByBike.byModel');
          this.selectedYearName = this.$t('searchByBike.byYear');
          break;
        case 'model':
          this.selectedModelId = null;
          this.selectedModelName = null;
          this.selectedYear = null;
          this.selectedYearName = null;
          this.selectedModelName = this.$t('searchByBike.byModel');
          this.selectedYearName = this.$t('searchByBike.byYear');
          break;
        case 'year':
          this.selectedYear = null;
          this.selectedYearName = null;
          this.selectedYearName = this.$t('searchByBike.byYear');
          break;
      }
    },
    search: function() {
      if (this.searchStatusCheck()) {
        return;
      }
      this.$router.push({
        name: 'productListByBike',
        query: {
          bikeBrandId: this.selectedBrandId,
          bikeSeriesId: this.selectedSeriesId,
          motoModelId: this.selectedModelId,
          year: 2023,
        },
      });
    },
    searchStatusCheck: function() {
      this.searchButtonDisabled = (this.selectedBrandId == null ||
          this.selectedSeriesId == null || this.selectedModelId == null)
      return this.searchButtonDisabled;
    }
  },
}
</script>

<style scoped>
  .search-in {
    width:100%;
    display:flex;
    align-items: center;
    justify-content:center;
  }
  .dp-container {
    position:relative;
    margin-left: 12px;
    width:20%;
    max-width: 180px;
    min-width:40px;
    .dp-item {
      flex:1;
      width:100%;
      height: 44px;
      border: solid 1px #ececec;
      background-color: white;
      text-align: left;
    }
    .icon {
      position: absolute;
      right:10px;
      top:12px;
      font-size:20px;
      color:#ec2f00;
      &:hover {color: #ec2f00;
         visibility: visible;}
    }
  }
  .btn-search {flex:0 1 auto; background-color:#ec2f00; width:137px; height:44px; color: white; margin:auto 12px; font-size:2rem}
  .btn-search:disabled {background-color:#cccccc; }
</style>
<style>
  #webSearchByBike .dp-item > .dropdown-toggle::after { border:0 }
  #webSearchByBike .dp-item button {text-align:left; font-size:1.4rem; margin-right:20px; overflow:hidden; text-overflow:ellipsis;}
  #webSearchByBike .dp-item > .dropdown-menu {width: 100%; font-size:1.4rem; min-height:35px; }
  #webSearchByBike .dp-item > .dropdown-menu a {overflow:hidden; text-overflow:ellipsis; font-size:1.2rem}
</style>
