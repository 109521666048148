var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    [
      _c(
        "b-row",
        { staticClass: "map-container" },
        [
          _c("b-col", { staticClass: "row lg" }, [
            _c(
              "div",
              { staticClass: "column" },
              [
                _c("router-link", { attrs: { to: { name: "index" } } }, [
                  _c("img", {
                    attrs: { src: "https://cdn.mobag.cn/WechatIMG106.jpg" }
                  })
                ]),
                _vm._v(" "),
                _c("router-link", { attrs: { to: { name: "index" } } }, [
                  _c("img", {
                    attrs: { src: "https://cdn.mobag.cn/WechatIMG121.jpg" }
                  })
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "column" },
              [
                _c("router-link", { attrs: { to: { name: "index" } } }, [
                  _c("img", {
                    attrs: { src: "https://cdn.mobag.cn/WechatIMG103.jpg" }
                  })
                ]),
                _vm._v(" "),
                _c("router-link", { attrs: { to: { name: "index" } } }, [
                  _c("img", {
                    attrs: { src: "https://cdn.mobag.cn/WechatIMG108.jpg" }
                  })
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "column" },
              [
                _c("router-link", { attrs: { to: { name: "index" } } }, [
                  _c("img", {
                    attrs: { src: "https://cdn.mobag.cn/WechatIMG107.jpg" }
                  })
                ]),
                _vm._v(" "),
                _c("router-link", { attrs: { to: { name: "index" } } }, [
                  _c("img", {
                    attrs: { src: "https://cdn.mobag.cn/WechatIMG124.jpg" }
                  })
                ])
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("b-col", { staticClass: "row sm" }, [
            _c(
              "div",
              { staticClass: "column" },
              [
                _c("router-link", { attrs: { to: { name: "index" } } }, [
                  _c("img", {
                    attrs: { src: "https://cdn.mobag.cn/WechatIMG106.jpg" }
                  })
                ]),
                _vm._v(" "),
                _c("router-link", { attrs: { to: { name: "index" } } }, [
                  _c("img", {
                    attrs: { src: "https://cdn.mobag.cn/WechatIMG121.jpg" }
                  })
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "column" },
              [
                _c("router-link", { attrs: { to: { name: "index" } } }, [
                  _c("img", {
                    attrs: { src: "https://cdn.mobag.cn/WechatIMG103.jpg" }
                  })
                ]),
                _vm._v(" "),
                _c("router-link", { attrs: { to: { name: "index" } } }, [
                  _c("img", {
                    attrs: { src: "https://cdn.mobag.cn/WechatIMG108.jpg" }
                  })
                ])
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }