<template xmlns:v-bind="http://www.w3.org/1999/xhtml">
    <b-container>
      <b-row class="map-container">
        <b-col class="row lg">
          <div class="column">
            <router-link :to="{name: 'index'}">
              <img src="https://cdn.mobag.cn/WechatIMG106.jpg">
            </router-link>
            <router-link :to="{name: 'index'}">
              <img src="https://cdn.mobag.cn/WechatIMG121.jpg">
            </router-link>
          </div>
          <div class="column">
            <router-link :to="{name: 'index'}">
              <img src="https://cdn.mobag.cn/WechatIMG103.jpg">
            </router-link>
            <router-link :to="{name: 'index'}">
              <img src="https://cdn.mobag.cn/WechatIMG108.jpg">
            </router-link>
          </div>
          <div class="column">
            <router-link :to="{name: 'index'}">
              <img src="https://cdn.mobag.cn/WechatIMG107.jpg">
            </router-link>
            <router-link :to="{name: 'index'}">
              <img src="https://cdn.mobag.cn/WechatIMG124.jpg">
            </router-link>
          </div>
        </b-col>
        <b-col class="row sm">
          <div class="column">
            <router-link :to="{name: 'index'}">
              <img src="https://cdn.mobag.cn/WechatIMG106.jpg">
            </router-link>
            <router-link :to="{name: 'index'}">
              <img src="https://cdn.mobag.cn/WechatIMG121.jpg">
            </router-link>
<!--            <router-link :to="{name: 'productListBySearch', query: {brands: '7', keyword:''}}">-->
<!--              <img src="https://cdn.mobag.cn/tbr_2.png">-->
<!--            </router-link>-->
          </div>
          <div class="column">
            <router-link :to="{name: 'index'}">
              <img src="https://cdn.mobag.cn/WechatIMG103.jpg">
            </router-link>
            <router-link :to="{name: 'index'}">
              <img src="https://cdn.mobag.cn/WechatIMG108.jpg">
            </router-link>
<!--            <router-link :to="{name: 'productListBySearch', query: {brands: '7', keyword:''}}">-->
<!--              <img src="https://cdn.mobag.cn/k_3.jpg">-->
<!--            </router-link>-->
          </div>
        </b-col>
      </b-row>
    </b-container>
</template>

<script>
  export default {}
</script>

<style scoped>
  .map-container{ padding:4px}
  img {width:100%;}
  .row {display: flex; justify-content:center; margin:0 }
  .column { display:flex; flex-direction: column; width:33%; padding:0}
  .column a { margin: 2px;}
  .sm{display: none}
  .container{max-width:1200px; width:100%; padding:0;}
  .col{padding:0}
  @media screen and (max-width: 767px) {
    .sm { display: flex;}
    .lg {display: none;}
    .column {width:50%; padding:0}
  }
</style>
