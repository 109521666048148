<template>
  <div style="padding-bottom: 50px;">
    <div class="top-container" style="text-align: center;">
      <p class="title-size">{{pageTitle}}</p>
    </div>

    <div class="main-list">
      <b-table style="text-align: center; font-size: 16px;"
               :items="pageList" :fields="fields" :emptyText="emptyText"
               striped show-empty>
        <template v-slot:cell(shareImage)="data">
          <div>
            <a style="cursor: pointer;" @click='linkToQuotationPage(data.item.urlSuffix)'>
              <img style="height:80px;" :src="operaImg(data.item.shareImage)" onerror="this.src='https://cdn.mobag.cn/9dc79963d75b6b8785c47caf8de091a3?imageView2/2/w/100/h/100'">
            </a>
          </div>
        </template>
        <template v-slot:cell(title)="data">
          <div class="center">
            <router-link :to="{ name: 'websiteQuotationPage', params: {urlSuffix: data.item.urlSuffix} }">{{data.item.title}}</router-link>
          </div>
        </template>
        <template v-slot:cell(shareDescription)="data">
          <div class="center"><p @click="linkToQuotationPage(data.item.urlSuffix)">{{data.item.shareDescription}}</p></div>
        </template>
      </b-table>
    </div>
    <img-enlarged ref="imgEnlarged" @tangle="tangleImgEnlarged()" />

    <div class="main-list-mobile">
      <mobile-list-item
        class="mobileItems"
        v-for="(item, index) in pageList"
        v-bind:key="index"
        :item='item'
     />
     <p class="emptyForMobile" v-show="pageList.length===0" style="font-size:1.2rem">{{emptyText}}</p>
    </div>
  </div>
</template>

<script>
import ImgEnlarged from '../../components/public/ImgEnlarged.vue';
import MobileListItem from '../../components/public/MobileQuotationPageListItem.vue';
import {baseUrl} from "../../util";

export default {
  components: {
    'img-enlarged': ImgEnlarged,
    'mobile-list-item': MobileListItem,
  },
  data: function() {
    return {
      fields: [
        { key: 'shareImage', label: '', thStyle:{'width':'150px','background-color':'#ec2f00','color':'#fff'} },
        { key: 'title', label: '题目', thStyle:{'width':'250px','background-color':'#ec2f00','color':'#fff'} },
        { key: 'shareDescription', label: '描述', thStyle:{'background-color':'#ec2f00','color':'#fff'} },
      ],
      emptyText: '',
      pageList: [],
      tagUrl: `/query_quotation_page_list`,
      isImgEnlargedOpen: false,
      pageTitle: ''
    }
  },
  created: function() {
    window.document.title = 'Best Value-选购指南';
    this.$api.get(this.tagUrl)
        .then(data => {
          this.pageList = data.data.page_list;
      });
  },
  methods: {
    operaImg(url) {
      return this.operaUrlTo150(url);
    },
    tangleImgEnlarged(imgUrl) {
      this.isImgEnlargedOpen = !this.isImgEnlargedOpen;
      this.$refs.imgEnlarged.handleParentClick(this.isImgEnlargedOpen);
      this.$refs.imgEnlarged.setImgUrl(this.operaUrlTo400(imgUrl));
    },
    operaUrlTo400(url) {
      if (!url) {
        return url;
      }
      const checkUrl = url.search('imageView');
      if (checkUrl !== -1) {
        const base = url.split('/w/')[0];
        return `${base}/w/400/h/400`;
      }
      return `${url}?imageView2/2/w/400/h/400`;
    },
    operaUrlTo150(url) {
      if (!url) {
        return url;
      }
      const checkUrl = url.search('imageView');
      if (checkUrl !== -1) {
        const base = url.split('/w/')[0];
        return `${base}/w/150/h/150`;
      }
      return `${url}?imageView2/2/w/150/h/150`;
    },
    linkToQuotationPage(suffix) {
        window.location.href = baseUrl.web + '#/home/product/quotation/' + suffix;
    }
  }
}
</script>
<style scoped lang="scss">
.main-list {border:1px solid #e1e1e1; max-width: 1000px;margin: 50px auto;}
.main-list-mobile, .mobile {display:none}
.center {
  text-align: center;
  padding-top: 30px;
}
.top-container{margin: 50px 0 0 0;}
.title-size{ font-size: 30px;}
.mobag-header {
  background-color: black;
  color: white;
  padding:10px;
  .mobag-header-container{
    max-width: 1000px;
    height: 64px;
    margin: 0 auto;
    align-items: center;
    .mobag-logo{width:180px;float: left;margin-top: 10px;}
    img {
      width: 110px;
    }
  }
  .dealer-login {
    float: right;
    text-align:center;
    margin: 0 15px;
    height: 60px;
    a{
      color: white;
      font-size:14px;
      line-height:60px;
      cursor: pointer;
      .icon {margin-right:4px; width:1.2rem; height:1.2rem; margin-top:-3px;}
    }
  }
}
@media screen and(max-width:575px) {
  .top-container {
    margin:50px 8px 8px 8px;
    padding-top:50px;
  }
}
@media screen and (max-width:767px) {
  .top-container{margin: 50px 0 0 0;}
  .page-top {
    margin:8px;
    .page-top-title, .search-btn-container{display:none}
  }
  .search-input {max-width:100%; margin-left:0}
  .dealer-route-container {padding:0px;}
  .main-list {display:none}
  .search-btn-container {display:none}
  .main-list-mobile {
    display:block;
    background-color:#fafafa;
    margin: 20px 20px;
  }
  .mobileItems:last-child {border-bottom:1px solid #eee}
  .emptyForMobile {margin:10px;}
  .web {display:none}
  .mobile{display: block}
  .title-size{ font-size: 24px;}
  .mobag-header {
    padding:10px;
    .mobag-header-container {
      .mobag-logo{width:120px;float: left;margin-top: 10px;}
      img{ width:110px;}
    }
    .dealer-login {
      float: right;
      text-align:center;
      margin: 0 5px;
      height: 60px;
      a{
        color: white;
        font-size:12px;
        line-height:60px;
        cursor: pointer;
        .icon {margin-right:4px; width:1.2rem; height:1.2rem; margin-top:-3px;}
      }
    }
  }
}
</style>