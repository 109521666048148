var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mobile", class: [!_vm.tangleMenuModal ? "close" : ""] },
    [
      _c("MobileHeader", {
        on: {
          close: function($event) {
            return _vm.close()
          }
        }
      }),
      _vm._v(" "),
      _c(
        "b-list-group",
        [
          _c(
            "b-list-group-item",
            {
              on: {
                click: function($event) {
                  return _vm.close()
                }
              }
            },
            [
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function(e) {
                      _vm.reloadPage("/home", e)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("header.homepage")))]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "b-list-group-item",
            {
              on: {
                click: function($event) {
                  return _vm.close()
                }
              }
            },
            [
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function(e) {
                      _vm.reloadPage("/home/product/list/tag/0", e)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("header.parts")))]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "b-list-group-item",
            {
              on: {
                click: function($event) {
                  return _vm.close()
                }
              }
            },
            [
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function(e) {
                      _vm.reloadPage("/home/product/list/tag/4", e)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("header.allGears")))]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "b-list-group-item",
            {
              on: {
                click: function($event) {
                  return _vm.close()
                }
              }
            },
            [
              _c(
                "a",
                {
                  attrs: {
                    target: "_blank",
                    href: "https://dealer.bestvkitchen.com/"
                  }
                },
                [_vm._v(_vm._s(_vm.$t("header.dealerLogin")))]
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }