var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "background-color": "#fafafa" } },
    [
      _c(
        "b-container",
        {
          staticClass: "b-container",
          staticStyle: { "background-color": "#fff" },
          attrs: { fluid: "" }
        },
        [
          _c(
            "b-row",
            { staticClass: "top-container" },
            [
              _c("b-col", { attrs: { md: "6", lg: "6", sm: "12" } }, [
                _c("div", { staticClass: "bd" }, [
                  _vm.headImgItem.kind == 0
                    ? _c("video", {
                        staticClass: "video",
                        attrs: { controls: "", src: _vm.headImgItem.url }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.headImgItem.kind == 1,
                        expression: "headImgItem.kind == 1"
                      }
                    ],
                    staticStyle: { height: "240px" },
                    attrs: { src: _vm.headImgItem.url },
                    on: {
                      click: function($event) {
                        return _vm.showBigImageModal(_vm.headImgItem.url)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "hd" },
                  _vm._l(_vm.showMediaList, function(headImg, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "spic",
                        class: { on: index === _vm.selectedHeadImgIndex },
                        on: {
                          mouseover: function($event) {
                            _vm.selectedHeadImgIndex = index
                          }
                        }
                      },
                      [
                        headImg.kind == 1
                          ? _c("img", { attrs: { src: headImg.url } })
                          : _c("div", { staticClass: "video-img-box" }, [
                              _c("img", {
                                staticClass: "video-play-icon",
                                attrs: { src: require("../../images/play.png") }
                              }),
                              _vm._v(" "),
                              _c("img", {
                                attrs: { src: _vm.getVideoImg(index) }
                              })
                            ])
                      ]
                    )
                  }),
                  0
                )
              ]),
              _vm._v(" "),
              _c(
                "b-col",
                {
                  staticClass: "top-right",
                  attrs: { md: "6", lg: "6", sm: "12" }
                },
                [
                  _vm.product.chineseTitle
                    ? _c("h2", { staticClass: "font18 fw600" }, [
                        _vm._v(_vm._s(_vm.product.chineseTitle))
                      ])
                    : _c("h2", { staticClass: "font18 fw600" }, [
                        _vm._v(_vm._s(_vm.product.title))
                      ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _vm.product.partNumber != ""
                    ? _c("div", { staticClass: "font14" }, [
                        _vm._v("SKU：" + _vm._s(_vm.product.partNumber))
                      ])
                    : _vm._e(),
                  _c("br"),
                  _vm._v(" "),
                  _vm.product.inventory === 0
                    ? _c("div", { staticClass: "font14" }, [
                        _vm._v("Out of Stock")
                      ])
                    : _vm._e(),
                  _c("br"),
                  _vm._v(" "),
                  _vm.product.inventory > 0
                    ? _c("div", { staticClass: "font14" }, [_vm._v("In Stock")])
                    : _vm._e(),
                  _c("br"),
                  _vm._v(" "),
                  _vm._l(_vm.$bizCode.SpecType, function(specName, specType) {
                    return _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              specType > 0 &&
                              _vm.existedSpecIdList[specType].length > 0,
                            expression:
                              "specType > 0 && existedSpecIdList[specType].length > 0"
                          }
                        ],
                        key: specType,
                        staticClass: "selection-container"
                      },
                      [
                        _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(specName))
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "s-right" },
                          _vm._l(_vm.filterSpecList(specType), function(spec) {
                            return _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.existedSpecIdList[
                                      specType
                                    ].includes(spec.id),
                                    expression:
                                      "existedSpecIdList[specType].includes(spec.id)"
                                  }
                                ],
                                key: spec.id,
                                staticClass: "font12 sitem",
                                class: {
                                  on:
                                    spec.id === _vm.selectedSpecIdList[specType]
                                },
                                style: {
                                  color:
                                    _vm.canSelectSpecIdList.length === 0 ||
                                    _vm.canSelectSpecIdList.includes(spec.id) ||
                                    spec.id === _vm.selectedSpecIdList[specType]
                                      ? "#000"
                                      : "#F1F1F1"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.selectSpec(spec.id, specType)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t" +
                                    _vm._s(spec.title) +
                                    "\n\t\t\t\t\t\t\t"
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    { staticClass: "btn-buy font18 fw600" },
                    [
                      _c(
                        "router-link",
                        {
                          staticStyle: { color: "#fff" },
                          attrs: { to: "/about/how_2_buy" }
                        },
                        [_vm._v("How to Buy")]
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-container",
        [
          _c(
            "b-row",
            { staticClass: "bottom-container" },
            [
              _c("b-col", [
                _c("div", {
                  staticClass: "details-main",
                  domProps: { innerHTML: _vm._s(_vm.product.description) }
                })
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "bottom-container-recommend" },
            [
              _c("b-col", [
                _vm.hasRelatedProducts
                  ? _c("div", { staticClass: "recommend" }, [
                      _c("h4", { staticClass: "recommend-title" }, [
                        _vm._v("相关推荐：")
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "recommend-list" },
                        [
                          _vm._l(_vm.product.relatedProducts, function(
                            item,
                            index
                          ) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "recommend-item",
                                on: {
                                  click: function($event) {
                                    return _vm.showProductDetail(item)
                                  }
                                }
                              },
                              [
                                _c("div", { staticClass: "relative" }, [
                                  _c("img", {
                                    staticClass: "recommend-image",
                                    attrs: {
                                      src:
                                        item.headImgList[0] &&
                                        item.headImgList[0].url
                                    }
                                  })
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "recommend-info-box" },
                                  [
                                    _c("h5", [
                                      _vm._v(
                                        _vm._s(item.chineseTitle || item.title)
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: item.partNumber,
                                            expression: "item.partNumber"
                                          }
                                        ],
                                        staticClass: "recommend-number"
                                      },
                                      [
                                        _vm._v(
                                          "配件编号: " + _vm._s(item.partNumber)
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: item.dragNumber,
                                            expression: "item.dragNumber"
                                          }
                                        ],
                                        staticClass: "recommend-number"
                                      },
                                      [
                                        _vm._v(
                                          "Drag编号: " + _vm._s(item.dragNumber)
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _vm.product.relatedProducts.length % 3 == 2
                            ? _c("div", {
                                staticClass: "recommend-item empty-box"
                              })
                            : _vm._e()
                        ],
                        2
                      )
                    ])
                  : _vm._e()
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "qrcode",
            "header-bg-variant": _vm.headerBgVariant,
            "header-text-variant": _vm.headerTextVariant,
            "body-bg-variant": _vm.bodyBgVariant,
            "body-text-variant": _vm.bodyTextVariant,
            size: "lg",
            "hide-footer": ""
          },
          on: { ok: _vm.hide },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function() {
                return [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "24px",
                        padding: "10px",
                        color: "#fff"
                      }
                    },
                    [_vm._v("添加客服")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm._v(" "),
          _c("div", { staticClass: "modal_body" }, [
            _c("p", [_vm._v("请用微信扫描下面的二维码，添加客服")]),
            _vm._v(" "),
            _c("img", { attrs: { src: _vm.randomeQrImage } })
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "button-lg-red mt60 font16",
              staticStyle: { color: "#fff" },
              on: { click: _vm.hide }
            },
            [_vm._v("确定")]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        { attrs: { id: "bigImage", "hide-footer": true, size: "lg" } },
        [
          _c("div", [
            _c("img", { staticClass: "big-img", attrs: { src: _vm.bigImage } })
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }