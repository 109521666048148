var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { staticClass: "mobag-header", staticStyle: { "min-width": "320px" } },
    [
      _c("div", { staticClass: "mobag-header-container" }, [
        _c("div", {
          staticClass: "mobag-menu-icon",
          on: {
            click: function($event) {
              return _vm.tangleMenuModal()
            }
          }
        }),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isRegister,
                expression: "isRegister"
              }
            ],
            staticClass: "mobag-nav regist"
          },
          [
            _c("h1", { staticClass: "flex1" }, [_vm._v("欢迎注册")]),
            _vm._v(" "),
            _c("div", { staticClass: "to-login" }, [
              _c(
                "a",
                {
                  on: {
                    click: function($event) {
                      return _vm.userLogin()
                    }
                  }
                },
                [_vm._v("已有账号？"), _c("span", [_vm._v("请登录")])]
              )
            ]),
            _vm._v(" "),
            _c("login", { attrs: { modalName: _vm.userloginModal } })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isForgetPassword,
                expression: "isForgetPassword"
              }
            ],
            staticClass: "mobag-nav regist"
          },
          [_c("h1", { staticClass: "flex1" }, [_vm._v("找回密码")])]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isWechatLogin,
                expression: "isWechatLogin"
              }
            ],
            staticClass: "mobag-nav regist"
          },
          [_c("h1", { staticClass: "flex1" }, [_vm._v("微信登录")])]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mobag-logo" }, [
      _c("a", { attrs: { href: "" } }, [
        _c("img", { attrs: { src: "https://cdn.mobag.cn/BestValueLogo.png" } })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }