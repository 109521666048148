var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "introduction-bg" }, [
        _c("div", { staticClass: "dialog-bg" }, [
          _c("div", { staticClass: "title" }, [
            _c("div", { staticClass: "bar" }),
            _vm._v(" "),
            _c("span", { staticClass: "en" }, [_vm._v("About Us")])
          ]),
          _vm._v(" "),
          _c("p", [_vm._v("Welcome to Best Value Kitchen!")]),
          _vm._v(" "),
          _c("p", [
            _c("b", [_vm._v("Company Overview:"), _c("br")]),
            _vm._v(
              "Best Value Kitchen is a kitchen cabinet and countertop design, fabrication, and installation company located in Farmingdale, Long Island, New York. We take pride in providing high-quality, cost-effective custom kitchen products."
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _c("b", [_vm._v("Mission and Values:"), _c("br")]),
            _vm._v(
              "Our mission is to provide customers with the best value kitchen solutions, ensuring 100% customer satisfaction. Crafting Excellence, Delivering Value - Your Kitchen, Our Passion."
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _c("b", [_vm._v("Our Team:"), _c("br")]),
            _vm._v(
              "The team at Best Value Kitchen consists of passionate, experienced professionals dedicated to meeting customer needs. Our designers, craftsmen, and installation team are committed to turning your dream kitchen into a reality."
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _c("b", [_vm._v("Our Commitment:"), _c("br")]),
            _vm._v(
              "At Best Value Kitchen, our commitment is to consistently exceed customer expectations. We achieve this by listening to your needs, offering personalized designs, using high-quality materials, and delivering exquisite craftsmanship to create your ideal kitchen."
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _c("b", [_vm._v("Corporate Social Responsibility:"), _c("br")]),
            _vm._v(
              "We are dedicated to addressing social and environmental issues, actively participating in community activities and environmental initiatives, and striving to create a more sustainable future.\n      "
            ),
            _c("br"),
            _vm._v(
              "Thank you for visiting the official website of Best Value Kitchen. Let us work together to create your dream kitchen!"
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "placeholder" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }