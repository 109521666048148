<template xmlns:v-bind="http://www.w3.org/1999/xhtml">
  <header class="mobag-header" style="min-width:320px;">
    <div class="mobag-header-container">
      <div class="mobag-menu-icon" @click="tangleMenuModal()"/>
      <div class="mobag-logo">
        <a href="">
          <img src="https://cdn.mobag.cn/BestValueLogo.png">
        </a>
      </div>
      <div class="mobag-nav regist" v-show="isRegister">
        <h1 class="flex1">欢迎注册</h1>
        <div class="to-login"><a @click="userLogin()">已有账号？<span>请登录</span></a></div>
        <login :modalName="userloginModal"></login>
      </div>
      <div class="mobag-nav regist" v-show="isForgetPassword">
        <h1 class="flex1">找回密码</h1>
      </div>
      <div class="mobag-nav regist" v-show="isWechatLogin">
        <h1 class="flex1">微信登录</h1>
      </div>
    </div>
  </header>
</template>

<script>
  import UserLoginDialogue from '../components/web/UserLoginDialogue.vue'

  export default {
    components: {
      'login': UserLoginDialogue,
    },
    data: function() {
      return {
        userloginModal: 'userLoginModal',
        isRegister: false,
        isForgetPassword: false,
        isWechatLogin: false
      }
    },
    mounted() {
      if(this.$route.name === 'register') {
        this.isRegister = true
      }
      if(this.$route.name === 'forgetPassword') {
        this.isForgetPassword = true
      }
      if(this.$route.name === 'wechatLogin') {
        this.isWechatLogin = true
      }
    },
    methods: {
      userLogin() {
        this.$modal.show(this.userloginModal,{})
      },
    },
  }
</script>

<style scoped lang="sass">
  h1 { font-family: PingFangSC; font-size:2.4rem; font-weight:500; color:white; margin-top:14px}
    .flex1 {flex:1}
    .to-login {align-items:'flex-end'; margin-top:10px; margin-right:1%; cursor:pointer}
    .regist {
      align-items:center;
      a {
        font-size:1.6rem;
        color: #ec2f00;
        &:hover{ text-decoration:underline}
    }
      span {color:#ec2f00}
  }
    .loginEntry{margin-left:20px;}
    .mobag-header {
      background-color: black;
      color: white;
      padding:10px;
      .mobag-header-container{
        width: 100%;
        padding: 0 7%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        .mobag-menu-icon{
          width: 100px
      }
        img {
          width: 110px;
      }
        .mobag-nav {
          flex: 1 0 auto;
          display: flex;
          padding: 0 0 0 18px;
          a {
            padding: 20px 0;
            font-size: 1.6rem;
            color: white;
        }
      }
    }
  }
    @media screen and (max-width: 767px) {
    .mobag-header {
      padding:8px;
      .mobag-header-container {
        padding: 0 10px;
        img{ width:110px; padding-top:5px; padding-bottom:5px; }
          .mobag-menu-icon{display:none}
        .mobag-nav {
            a{font-size:1rem}
            h1{font-size:1.6rem;}
      }
    }
  }
  }
  @media screen and (max-width: 420px) {
    .to-login{display:none;}
  }


</style>
