<template>
  <div>
    <div class="introduction-bg">
      <div class="dialog-bg">
        <div class="title">
          <div class="bar"></div>
          <span class="en">Purchase</span>
        </div>
        <div>
          <p>Welcome to the How to Buy page at Best Value Kitchen. Here is a brief guide to our product purchasing process:</p>
          <p><b>Browse Products</b><br>
          We offer two options for browsing. You can explore our product range on our official website and choose from our kitchen cabinet and countertop designs. Additionally, you can visit our showroom in Farmingdale, where our professional sales team provides personalized and comprehensive services.
          </p>
          <p><b>Contact Us</b><br>
          If you have any questions about specific products or designs or need further information, please feel free to contact our customer service team.
          </p>
          <p><b>Schedule an On-site Survey</b><br>
          Get in touch with our customer service team to arrange a free on-site survey and quotation service. Our expert team will visit your home, assess the space, and provide personalized recommendations.
          </p>
          <p><b>Choose Products</b><br>
          Select the kitchen cabinets and countertop products that best suit your needs and space requirements.
          </p>
          <p><b>Place an Order</b><br>
          Confirm your product selection and place an order. Our team will discuss the best delivery time with you.
          </p>
          <p><b>Delivery and Installation</b><br>
          We will arrange product delivery and professional installation services according to your requirements. Your custom kitchen journey is about to begin!
          <br>If you need further assistance or have any questions, please don't hesitate to contact our customer service team.
          </p>
        </div>
      </div>
    </div>
    <div class="placeholder"></div>
  </div>
</template>

<script>

export default {
  created() {
    window.document.title = 'Best Value - How to Buy';
  }
}
</script>

<style scoped>
  .title {
    left: -190px;
  }
  .placeholder {height:45%;}
  .btn-buy{
		width: 146px;
		height: 44px;
		background-color: #ec2f00;
		color: #fff;
		line-height:1.6rem;
		text-align:center;
		border:0;
		margin-top:40px;
	}
  @media (max-width:900px) {
    .introduction-bg {
    .title {
      left:0;
      top: -100px;
    }
  }
  }
</style>
